.DraftEditor-root:focus {
  outline: none;
}
.editor {
  position: relative;
  box-sizing: border-box;
  cursor: text;
  /* padding: 0px 16px 8px 8px; */
  padding: 4px 16px 0px 8px;
  background: #fff;
}
.editor:focus,
.editor div:focus {
  outline: none;
}

.editor :global(.public-DraftEditor-content) {
  font-size: 14px;
  line-height: 22px;
  font-family: 'Roboto', sans-serif;
  color: #262626;
  /* min-height: 30px; */
  min-height: 22px;
  position: relative;
  z-index: 2;
  overflow-wrap: anywhere !important;
}

.editor :global(.public-DraftEditorPlaceholder-root) {
  position: absolute;
  z-index: 1;
}
.editor :global(.public-DraftEditorPlaceholder-inner) {
  /* position: absolute;
  top: 16px;
  left: 16px; */
  font-size: 14px;
  line-height: 22px;
  color: #bfbfbf;
  font-family: 'Roboto', sans-serif;
}
