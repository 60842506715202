.list {
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 15px;
  position: relative;
  width: 330px;
  height: 330px;
}

.listEmpty {
  composes: list;
}

.listEmpty::before {
  content: 'No results.';
  position: absolute;
  left: 50%;
  top: 50%;
  color: #aaa;
  transform: translate(-50%, -50%);
}

.listScrollbar {
  position: absolute;
  right: 0;
  top: 2px;
  bottom: 7px;
  width: 4px;
  background-color: #e0e0e0;
  border-radius: 2px;
  opacity: .1;
  transition: opacity .4s;
}

.list:hover .listScrollbar {
  opacity: .3;
}

.list .listScrollbar:hover,
.list .listScrollbar:active {
  opacity: .6;
}

.listScrollbarThumb {
  background-color: #000;
  border-radius: 2px;
  cursor: pointer;
}

.listMasonry {
  margin-left: 10px;
  width: 315px;
}

.listItem {
  margin-right: 5px;
  margin-bottom: 5px;
  width: 100px;
  background-color: #ccc;
  background-position: center;
  background-repeat: no-repeat;
}

.listEntry {
  padding: 0;
  display: block;
  background: none;
  border: none;
  cursor: pointer;
}

.listEntryImage {
  display: block;
}
