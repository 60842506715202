.pg-viewer-wrapper {
  .loading-container {
    align-items: center;
    display: flex !important;
    flex-direction: row;
    height: 100%;
    justify-content: center;
    width: 100%;

    .loading {
      background-image: url('../assets/loading-3-quarters.png');
      background-repeat: no-repeat;
      display: inline-block;
      height: 72px;
      width: 72px;
      animation: rotating 2s linear infinite;
    }
  }

  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}
