.mention {
  /* color: #4a85bb; */
  /* text-decoration: none; */
  width: 100%;
}

.mentionSuggestions {
  display: flex;
  flex-direction: column;
  align-items: inherit;
  padding: 0px;
  position: absolute;
  width: 205px;
  max-height: 150px;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 3px 4px rgba(0, 0, 0, 0.02),
    0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 8px 8px 0;
  z-index: 2;
  box-sizing: border-box;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.mentionSuggestionsEntry {
  /* padding: 7px 10px 3px 10px;
  transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56); */
  width: 100%;
  margin-bottom: 8px;
}

.mentionSuggestionsEntryFocused {
  composes: mentionSuggestionsEntry;
  background-color: #f0f5ff;
  width: 100%;
  margin-bottom: 8px;
  border-radius: 4px;
}
