.pg-viewer-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;

  .pg-viewer {
    height: 100%;
    position: relative;
    flex-grow: 2;

    .pg-driver-view {
      margin: auto;
      width: 100%;
      height: 100%;

      .loading {
        position: relative;
      }

      canvas,
      .react-grid-Container {
        width: 100%;
      }
    }
  }

  .pg-viewer-link {
    background: mediumpurple;
    height: 100%;
    flex-grow: 1;
  }
}
.react-grid-Container {
  margin: auto;
}

#xbim-viewer {
  height: 100%;
  width: 100%;
}

#app {
  background: pink;
}
