.pg-viewer-wrapper {
  .video-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    width: 100%;

    .video-loading {
      visibility: hidden;
    }
  }
}
