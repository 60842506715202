.unsupported-message {
  padding: 46px;
  background: white;
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .unsupported-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 316px;

    .unsupported-header {
      margin-top: 28px;
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0;
      text-align: center;
    }
    .unsupported-body {
      margin-top: 4px;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: center;
    }
  }
}
