.DraftEditor-root:focus {
  outline: none;
}
.editor {
  box-sizing: border-box;
  cursor: text;
  padding: 12px 16px;
  background: #fff;
  border-radius: 4px 4px 0 0;
}
.editor:focus,
.editor div:focus {
  outline: none;
}

.editor :global(.public-DraftEditor-content) {
  font-size: 14px;
  line-height: 22px;
  font-family: 'Roboto', sans-serif;
  color: #262626;
  /* Removing min-height, the editor is not responding properly */
  min-height: 22px;
  position: relative;
  z-index: 2;
}

.editor :global(.public-DraftEditorPlaceholder-root) {
  position: absolute;
  z-index: 1;
}
.editor :global(.public-DraftEditorPlaceholder-inner) {
  /* position: absolute;
  top: 16px;
  left: 16px; */
  font-size: 14px;
  line-height: 22px;
  color: #bfbfbf;
  font-family: 'Roboto', sans-serif;
}
