.select {
  padding: 15px 0;
  width: 340px;
  color: inherit;
  border: 1px solid #ccc;
}

.selectInput {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
  padding: .5em .75em;
  width: calc(100% - 30px);
  box-sizing: border-box;
  font-family: inherit;
  font-size: 1em;
}

.attribution {
  margin-left: 15px;
  margin-right: 15px;
}
